import { Component, OnInit, ViewChild , HostListener, Inject } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationStart  } from '@angular/router';
import { ApiService } from '../api.service';
import Swal from 'sweetalert2'
import { TranslateService } from '@ngx-translate/core';
 import { ModalManager } from 'ngb-modal';
 import { SharedService } from '../shared.service';
import { LocalstorageService } from '../localstorage.service';
import { SessionService } from '../session.service';
import { Title, Meta }     from '@angular/platform-browser';
import { MenusharedService } from '../menushared.service';
import { MenugetService } from '../menuget.service';
import { Angulartics2 } from 'angulartics2';
import { Angulartics2GoogleAnalyticsEnhancedEcommerce } from 'angulartics2/ga-enhanced-ecom';
declare var $: any;
import { UrlpipePipe } from '../urlpipe.pipe';
import { LowerCasePipe } from '@angular/common';
import { SeoService } from '../seo.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { CheckwebpService } from '../checkwebp.service';
import { OwlOptions  } from 'ngx-owl-carousel-o';
import { TitlechangeService } from '../titlechange.service';
import { Subscription } from 'rxjs';
import { OpenmodalloginService } from '../openmodallogin.service';
@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {
  @ViewChild('ModalDetail') ModalDetail;
  @ViewChild('ModalFilterMobile') ModalFilterMobile;
  private _subscription: Subscription[];
  entryNgInit:any;
  subscribeMenu:any;
  objectKeys = Object.keys;
  filter: any ={} ;
  filterInitial: any ={} ;
  orderby: any ={} ;
  setIntervalClear:any;
  brands:any;
  genres:any;
  subcat:any;
  cat:any;
  sub2cat:any;

  subcatStock = 0;
  catStock = 0;
  sub2catStock = 0;

  colors:any;
  sizes:any;
  breadcrumbs:Array<Object> = [];
  createBreadcrumbsP:any = [];
  createBreadcrumbsAll:any = [];
  userLogging: Boolean= false;
  orderItem:any;
  scrollPosition:any;
  itemsList:any;
  emptyItem:any;
  countParams:any;
    modalDetailRef:any;
    modalDetailRef2:any;
    selectItem:any;
    carouselOptionsDetail: OwlOptions = {
        loop:false,
    		margin:0,
        autoplay: true,
        autoHeight: true,
        autoWidth: true,
    		nav:true,
    		navText: ['<div class="prevBtn"><img alt="<" src="assets/images/lefticon.png"></div>', '<div class="nextBtn"><img alt=">" src="assets/images/righticon.png"></div>'],
    		items: 1
    }
    page:any;
    stopScroll:any;
    subscribe:any;
    descText:any;
    descTextMenu:any;
    menuParent:any;
    browserwebp:any;
    defaultImage:any;
    checkchangelang:any;
    loadingShow:any=false;
    deviceDesktop:any;

    countriesOrigin:any;
    urlsAlternate:any;
    public relateditemarr: any = [];

    loadingItems = [1,2,3,4,5,6,7,8];
    loadingColors = [1,2,3];


  constructor(public openmodalloginService:OpenmodalloginService,public sessionService: SessionService,public titlechangeService:TitlechangeService,private angulartics2GAEcommerce: Angulartics2GoogleAnalyticsEnhancedEcommerce, public checkwebp: CheckwebpService,public localize: LocalizeRouterService,private router: Router,private seoService: SeoService, private lowercase:LowerCasePipe, private urlpipePipe:UrlpipePipe, private menugetService:MenugetService, private menusharedService:MenusharedService, private angulartics2: Angulartics2, private metaService: Meta, public titleService: Title, public localstorageService: LocalstorageService, private modalService: ModalManager, private route: ActivatedRoute, public apiService: ApiService, public translate: TranslateService, public sharedService: SharedService) {
    this._subscription = [];
  this.checkchangelang=false;

    this.apiService.GET_TAGS_META.forEach(key => {
      this.metaService.removeTag('name="'+key+'"');
    });

    let self = this;
    window.addEventListener('storage', function(e) {
      self.userLogging = localstorageService.get("userLogging");
    })

    let sub50 = this.sessionService.getsessionEvent().subscribe((obj)=>{
       this.userLogging = obj[0];
    });
    this._subscription.push(sub50);

    this.countriesOrigin=[];

    this.browserwebp = this.checkwebp.canUseWebP();
    if(this.browserwebp){
      this.defaultImage = "assets/images/imgdefault.webp";
    }else{
      this.defaultImage = "assets/images/imgdefault.jpg";
    }
    this.entryNgInit=false;
this.itemsList = [];
    if(this.localstorageService.get('detailBack') && this.localstorageService.get('detailBack')==1){

      try{
        this.filter = this.localstorageService.get('show_filter');
        this.filterInitial = this.localstorageService.get('show_filterInitial');
        this.orderItem = this.localstorageService.get('show_orderItem');
        this.page = this.localstorageService.get('show_page');
        this.stopScroll = this.localstorageService.get('show_stopScroll');

        this.createBreadcrumbsP = this.localstorageService.get('createBreadcrumbsP');
        this.createBreadcrumbsAll = this.localstorageService.get('createBreadcrumbsAll');

            this.localstorageService.getasync('show_itemsList', 1).then((res) => this.extractData(res));





        this.countriesOrigin = this.localstorageService.get('show_countriesorigin');
        this.brands = this.localstorageService.get('show_brands');
        this.subcat = this.localstorageService.get('show_subcat');
        this.cat = this.localstorageService.get('show_cat');
        this.sub2cat = this.localstorageService.get('show_sub2cat');
        this.colors = this.localstorageService.get('show_colors');
        this.sizes = this.localstorageService.get('show_sizes');
        this.genres = this.localstorageService.get('show_genres');
        this.scrollPosition = this.localstorageService.get('show_position');
        this.emptyItem = this.localstorageService.get('show_emptyItem');

        if(this.filter.novelty==1){
          $("#new_filter").prop("checked", true);
        }

      /*  let allitems = this.keeplistservice.getdir();
        for (let index = 1; index <= this.page; index++) {
          this.itemsList = this.itemsList.concat(allitems[index]);
        }*/
      }catch(error){
        this.localstorageService.remove('detailBack');
        this.getItems(0);

      }
    }else{
      this.filter.menu = null;
      this.filter.genre = null;
      this.filter.cat = null;
      this.filter.subcat = null;
      this.filter.sub2cat = null;
      this.filter.brand = null;
      this.filter.countryorigin = null;
      this.filter.collection = null;
      this.filter.search = null;

      if(this.route.snapshot.paramMap.get('desc1')){
        if(this.route.snapshot.paramMap.get('desc1')=="novidades" || this.route.snapshot.paramMap.get('desc1')=="news" || this.route.snapshot.paramMap.get('desc1')=="nouveautes" || this.route.snapshot.paramMap.get('desc1')=="novedades"  ){
          this.filter['menu'] = -2;
          this.createBreadcrumbsP[0] = ["menu", -2];
        }else if(this.route.snapshot.paramMap.get('desc1')=="specials" || this.route.snapshot.paramMap.get('desc1')=="promotions" || this.route.snapshot.paramMap.get('desc1')=="promocoes"   ){
          this.filter['menu'] = -1;
          this.createBreadcrumbsP[0] = ["menu", -1];
        }else{
          let desc1 = this.geturlvalue(this.route.snapshot.paramMap.get('desc1'));
          let desc1_d = this.getdescriptionurl(desc1.slice(0,1));
          if(desc1_d!=="0"){
            desc1 = desc1.substring(1);
            if(desc1_d=="search"){
              desc1 = this.route.snapshot.paramMap.get('desc1').slice(0, -1);
            }
            this.filter[desc1_d] = desc1;
            this.createBreadcrumbsP[0] = [desc1_d, desc1];
          }else{
            this.router.navigate( [this.localize.translateRoute('/404')]  );return;
          }
        }
      }

      if(this.route.snapshot.paramMap.get('desc2')){
        let desc2 = this.geturlvalue(this.route.snapshot.paramMap.get('desc2'));
        let desc2_d = this.getdescriptionurl(desc2.slice(0,1));
        if(desc2_d!=="0"){
          desc2 = desc2.substring(1);
          if(desc2=="" || isNaN(Number(desc2))){
            this.router.navigate( [this.localize.translateRoute('/404')]  );return;
          }
          this.filter[desc2_d] = desc2;
          this.createBreadcrumbsP[1] = [desc2_d, desc2];
        }else{
          this.router.navigate( [this.localize.translateRoute('/404')]  );return;
        }
      }

      if(this.route.snapshot.paramMap.get('desc3')){
        let desc3 = this.geturlvalue(this.route.snapshot.paramMap.get('desc3'));
        let desc3_d = this.getdescriptionurl(desc3.slice(0, 1));
        if(desc3_d!=="0"){
          desc3 = desc3.substring(1);
          if(desc3=="" || isNaN(Number(desc3))){
            this.router.navigate( [this.localize.translateRoute('/404')]  );return;
          }
          this.filter[desc3_d] = desc3;
          this.createBreadcrumbsP[2] = [desc3_d, desc3];
        }else{
          this.router.navigate( [this.localize.translateRoute('/404')]  );return;
        }
      }



      if(this.route.snapshot.paramMap.get('desc4')){
        let desc4 = this.geturlvalue(this.route.snapshot.paramMap.get('desc4'));
        let desc4_d = this.getdescriptionurl(desc4.slice(0,1));
        if(desc4_d!=="0"){
          desc4 = desc4.substring(1);
          if(desc4=="" || isNaN(Number(desc4))){
            this.router.navigate( [this.localize.translateRoute('/404')]  , {skipLocationChange: true});return;
          }
          this.filter[desc4_d] = desc4;
          this.createBreadcrumbsP[3] = [desc4_d, desc4];
        }else{
          this.router.navigate( [this.localize.translateRoute('/404')]  , {skipLocationChange: true});return;
        }
      }


      if(this.route.snapshot.paramMap.get('desc5')){
        let desc5 = this.geturlvalue(this.route.snapshot.paramMap.get('desc5'));
        let desc5_d = this.getdescriptionurl(desc5.slice(0,1));
        if(desc5_d!=="0"){
          desc5 = desc5.substring(1);
          if(desc5=="" || isNaN(Number(desc5))){
            this.router.navigate( [this.localize.translateRoute('/404')]  , {skipLocationChange: true});return;
          }
          this.filter[desc5_d] = desc5;
          this.createBreadcrumbsP[4] = [desc5_d, desc5];
        }else{
          this.router.navigate( [this.localize.translateRoute('/404')]  , {skipLocationChange: true});return;
        }
      }



    /*  if(this.route.snapshot.paramMap.get('paramname1')){
        this.filter[this.route.snapshot.paramMap.get('paramname1')] = this.route.snapshot.paramMap.get('paramval1');
      }

      if(this.route.snapshot.paramMap.get('paramname2')){
        this.filter[this.route.snapshot.paramMap.get('paramname2')] = this.route.snapshot.paramMap.get('paramval2');
      }
      if(this.route.snapshot.paramMap.get('paramname3')){
        this.filter[this.route.snapshot.paramMap.get('paramname3')] = this.route.snapshot.paramMap.get('paramval3');
      }*/


      this.orderItem = 0;
      this.filter.color=null;
      this.filter.size = null;
      this.filter.novelty=0;
      this.filterInitial = Object.assign({}, this.filter);
      this.page=1;
      this.stopScroll=false;
      this.scrollPosition=0;
      this.emptyItem = 0;
    }



    this.route.params.subscribe(params => {

    /*  if(params['desc1'] && params['desc1']=="search"){
        this.filter.search = params['paramval1'];
      }*/

      this.filter.search = null;
      this.filterInitial.search = null;
      if(params['desc1']){
        let desc1_search = this.geturlvalue(params['desc1']);
        let desc1_d_search = this.getdescriptionurl(desc1_search.slice(0,1));
        if(desc1_d_search=="search"){
          this.checkchangelang=false;
          desc1_search = params['desc1'].slice(0, -1);
          this.filter.search = desc1_search;
          this.filterInitial.search = desc1_search;
          this.createBreadcrumbsP = [];
          this.createBreadcrumbsP[0] = ["e", desc1_search];



          if(params['desc2']){
            let desc2 = this.geturlvalue(params['desc2']);
            let desc2_d = this.getdescriptionurl(desc2.slice(0,1));
            if(desc2_d!=="0"){
              desc2 = desc2.substring(1);

              this.createBreadcrumbsP[1] = [desc2_d, desc2];
            }
          }

          if(params['desc3']){
            let desc3 = this.geturlvalue(params['desc3']);
            let desc3_d = this.getdescriptionurl(desc3.slice(0, 1));
            if(desc3_d!=="0"){
              desc3 = desc3.substring(1);
              this.createBreadcrumbsP[2] = [desc3_d, desc3];
            }
          }


          if(params['desc4']){
            let desc4 = this.geturlvalue(params['desc4']);
            let desc4_d = this.getdescriptionurl(desc4.slice(0,1));
            if(desc4_d!=="0"){
              desc4 = desc4.substring(1);
              this.createBreadcrumbsP[3] = [desc4_d, desc4];
            }
          }


        }
      }


      if(this.countParams==Object.keys(params).length && this.checkchangelang==false){
        this.createBreadcrumbsP = [];
        this.filter.menu = null;
        this.filter.genre = null;
        this.filter.cat = null;
        this.filter.subcat = null;
        this.filter.sub2cat = null;
        this.filter.brand = null;
        this.filter.countryorigin = null;
        this.filter.collection = null;
        this.filter.color=null;
        this.filter.size = null;

        this.orderItem = 0;
        this.filterInitial.menu = null;
        this.filterInitial.genre = null;
        this.filterInitial.cat = null;
        this.filterInitial.subcat = null;
        this.filterInitial.sub2cat = null;
        this.filterInitial.brand = null;
        this.filterInitial.countryorigin = null;
        this.filterInitial.collection = null;
        this.filterInitial.color=null;
         /*if(params['paramname1']){
           this.filterInitial[params['paramname1']] = params['paramval1'];
           this.filter[params['paramname1']] = params['paramval1'];
         }
         if(params['paramname2']){
           this.filterInitial[params['paramname2']] = params['paramval2'];
           this.filter[params['paramname2']] = params['paramval2'];
         }
         if(params['paramname3']){
           this.filterInitial[params['paramname3']] = params['paramval3'];
           this.filter[params['paramname3']] = params['paramval3'];
         }*/


         if(params['desc1']){
           if(params['desc1']=="novidades" || params['desc1']=="news" || params['desc1']=="nouveautes" || params['desc1']=="novedades"  ){
             this.filter['menu'] = -2;
             this.filterInitial['menu'] = -2;
             this.createBreadcrumbsP[0] = ["menu", -2];
           }else if(params['desc1']=="specials" || params['desc1']=="promotions" || params['desc1']=="promocoes"   ){
             this.filter['menu'] = -1;
             this.filterInitial['menu'] = -1;
             this.createBreadcrumbsP[0] = ["menu", -1];
           }else{
              let desc1 = this.geturlvalue(params['desc1']);
              let desc1_d = this.getdescriptionurl(desc1.slice(0,1));
              if(desc1_d!=="0"){
                desc1 = desc1.substring(1);
                if(desc1_d=="search"){
                  desc1 = params['desc1'].slice(0, -1);
                }
                this.createBreadcrumbsP[0] = [desc1_d, desc1];
                this.filter[desc1_d] = desc1;
                this.filterInitial[desc1_d] = desc1;
              }
            }
          }

          if(params['desc2']){
            let desc2 = this.geturlvalue(params['desc2']);
            let desc2_d = this.getdescriptionurl(desc2.slice(0,1));
            if(desc2_d!=="0"){
              desc2 = desc2.substring(1);
              this.filter[desc2_d] = desc2;
              this.filterInitial[desc2_d] = desc2;
              this.createBreadcrumbsP[1] = [desc2_d, desc2];
            }
          }

          if(params['desc3']){
            let desc3 = this.geturlvalue(params['desc3']);
            let desc3_d = this.getdescriptionurl(desc3.slice(0, 1));
            if(desc3_d!=="0"){
              desc3 = desc3.substring(1);
              this.filter[desc3_d] = desc3;
              this.filterInitial[desc3_d] = desc3;
              this.createBreadcrumbsP[2] = [desc3_d, desc3];
            }
          }


          if(params['desc4']){
            let desc4 = this.geturlvalue(params['desc4']);
            let desc4_d = this.getdescriptionurl(desc4.slice(0,1));
            if(desc4_d!=="0"){
              desc4 = desc4.substring(1);
              this.filter[desc4_d] = desc4;
              this.filterInitial[desc4_d] = desc4;
              this.createBreadcrumbsP[3] = [desc4_d, desc4];
            }
          }

          if(params['desc5']){
            let desc5 = this.geturlvalue(params['desc5']);
            let desc5_d = this.getdescriptionurl(desc5.slice(0,1));
            if(desc5_d!=="0"){
              desc5 = desc5.substring(1);
              this.filter[desc5_d] = desc5;
              this.filterInitial[desc5_d] = desc5;
              this.createBreadcrumbsP[4] = [desc5_d, desc5];
            }
          }

        if(this.localstorageService.get('detailBack')){
          this.localstorageService.remove('detailBack');
        }
        this.itemsList=null;
        //window.scroll(0,0);
        this.ngOnInit();
      }
      if(this.checkchangelang){
        this.checkchangelang=false;
      }
      this.localstorageService.set('show_filterInitial', this.filterInitial);
      this.localstorageService.set('show_filter', this.filter);
      this.countParams = Object.keys(params).length;
    });

    this.subscribeMenu =  this.menusharedService.get().subscribe((obj)=>{
         this.menuParent = obj[0];
         //this.checkchangelang=true;
         //this.createBreadcrumbs();
         //this.checkchangelang=false;
    });

    /*this.route.url.subscribe(params => {
      if(!this.checkchangelang){
        this.createBreadcrumbs();
      }
    });*/

    this.menugetService.send();




	this.subscribe =  this.translate.onLangChange.subscribe(lang=>{
    this.checkchangelang=true;
      this.apiService.GET_TAGS_META.forEach(key => {
        this.metaService.removeTag('name="'+key+'"');
      });


         let desc1_search = this.geturlvalue(this.route.snapshot.paramMap.get('desc1'));
         let desc1_d_search = this.getdescriptionurl(desc1_search.slice(0,1));

         if(desc1_d_search!="search"){

           this.breadcrumbs = [];
          if(this.createBreadcrumbsAll){
              for (let index = 0; index < this.createBreadcrumbsAll.length; index++) {

                this.breadcrumbs.push({ "url": (typeof this.createBreadcrumbsAll[index]["url"][lang.lang]!="undefined" ? this.createBreadcrumbsAll[index]["url"][lang.lang] : this.createBreadcrumbsAll[index]["url"][0] ), "description":(typeof this.createBreadcrumbsAll[index]["description"][lang.lang]!="undefined" ? this.createBreadcrumbsAll[index]["description"][lang.lang] : this.createBreadcrumbsAll[index]["description"][0] ) });
              }
              this.savebreadcrumbs(this.breadcrumbs);
          }

           let op = 1;
           if(this.menuParent && this.menuParent.length>0){


             let parse = this.urlsAlternate[lang.lang].replace(this.apiService.PHP_API_URL_DEFAULT+"/", "");

             parse = parse.split('/');
             parse.shift();

               for (let ix = 1; ix < this.route.snapshot.url.length; ix++) {
                 this.route.snapshot.url[ix].path = parse[ix]  ;
               }


          /*  let rl = this.replaceurl(this.menuParent,  op);

            if(rl<this.route.snapshot.url.length){
              for (let ix = rl; ix < this.route.snapshot.url.length; ix++) {
                this.route.snapshot.url[ix].path = this.lowercase.transform(this.urlpipePipe.transform(this.createBreadcrumbsAll[ix-1]["description"][lang.lang]))+"-"+this.createBreadcrumbsAll[ix-1]["full_last"]  ;
              }
            }*/

          }
         }else{
         this.translate.get('searchresult').subscribe(translations => {
            this.breadcrumbs = [];
  					let obj: any = {};
  					obj.url = ["/show/"+this.filterInitial.search + "e"];
  					obj.description = translations + this.filterInitial.search;
  					this.breadcrumbs.push(obj);
  				});

          let parse = this.urlsAlternate[lang.lang].replace(this.apiService.PHP_API_URL_DEFAULT+"/", "");

          parse = parse.split('/');
          parse.shift();

            for (let ix = 1; ix < this.route.snapshot.url.length; ix++) {
              this.route.snapshot.url[ix].path = parse[ix]  ;
            }

          this.savebreadcrumbs(this.breadcrumbs);
        }

        this.apiService.GET_TAGS_META.forEach(key => {
          this.metaService.removeTag('name="' + key + '"');
        });
        this.seoService.clearAlternateUrl();
        this.geturlalternate();
        this.metatags(lang.lang);
    });

    if(this.localstorageService.get("userLogging")){
      this.userLogging=this.localstorageService.get("userLogging");
    }
    this.getWarning(this.translate.currentLang);
  }

openmenumobile(){
  this.modalDetailRef2 = this.modalService.open(this.ModalFilterMobile, {
      size: 'md',
      windowClass: 'modal-md'  ,
      centered: true,
      animation: true,
      hideCloseButton: true,
      keyboard: false,
      closeOnOutsideClick: false,
      backdropClass: 'modal-backdrop'
  });
  this.modalDetailRef2.onOpen.subscribe(() => {
      $("modal .fade.show").addClass("in");
  });
}


getRelated(){
  this.apiService.getItemsLimit().subscribe((obj: any)=>{
     if(typeof obj!=="undefined" && obj.code==200){
       this.relateditemarr = obj.info;
     }else{
       this.relateditemarr = [];
     }
  });
}

getoneimg(item:any,idcolor:any){
  let imgs = this.imgArr(item, idcolor);
  if(this.browserwebp && imgs[0].webp){
    return this.apiService.imagesDir+imgs[0].webp;
  }
  return this.apiService.imagesDir+ imgs[0].photo;
}

imgArr(item:any,idcolor:any){
  if(item.itemwithattr==1){
    if(idcolor){
      let size = Object.keys(item.colors[idcolor])[0];
        return item.colors[idcolor][size].images;
    }else{
      if(item.colorpriority && item.colorpriority in item.colors){
        let size = Object.keys(item.colors[item.colorpriority])[0];
         return item.colors[item.colorpriority][size].images;
      }else{
        let color = Object.keys(item.colors)[0];
        let size = Object.keys(item.colors[color])[0];
        return item.colors[color][size].images;
      }
    }
  }else{
    return item.images;
  }
}

getPriceRelated(product){
  if(product.itemwithattr==1){
    let color = Object.keys(product.colors)[0];
    let size = Object.keys(product.colors[color])[0];
    return product.colors[color][size].priceFinalTxt;
  }else{
    return product.priceFinalTxt;
  }
}


closefiltersBody(){
  let r = document.getElementsByClassName("aspect-input");
  if(r){
    let rt;
    for (let index = 0; index < r.length; index++) {
      rt = r[index] as HTMLInputElement
      rt.checked = false;
    }
  }
}

  parseText(desc, limit){
    let e = document.createElement('div');
    e.innerHTML = desc;
    let r = e.childNodes[0].nodeValue;
    if(desc.length<=limit){
      return r.substring(0,limit) ;
    }
    return r.substring(0,limit)+"...";
  }


  divmove(elem, op){
    if(op==1){
      elem.scrollLeft+=100;
    }else{
      elem.scrollLeft-=100;
    }
  }

  checkElemetScroll(elem, left, right){
    if(elem.scrollLeft==0){
      elem.scrollLeft = 1;
    }
    if(elem.scrollLeft==0){
      left.style.display = 'none';
      right.style.display = 'none';
    }else{
      left.style.display = 'block';
      right.style.display = 'block';
    }
  }

  getWarning(lang){
    this.apiService.getWarning(lang, 1).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(!obj.info || Object.values(obj.info).length==0){
          return 0;
        }
        let warning_ =  this.localstorageService.get("warning_");
        let datenow:any = new Date();
        datenow = datenow.toISOString().split('T')[0];
        let newwar = [];
        if(warning_){
          newwar = warning_;
          let entry = false;
          for (let index = 0; index < warning_.length; index++) {
             if(warning_[index][0]==obj.info.idwebsitewarning.toString() && warning_[index][1]==datenow){
               entry = true; break;
             }
             if(warning_[index][0]==obj.info.idwebsitewarning.toString()){
               newwar.splice(index, 1);
             }
          }
          if(!entry){
            newwar.push([obj.info.idwebsitewarning.toString(), datenow]);

            this.localstorageService.set("warning_", newwar);
            Swal.fire({
              title: obj.info.name,
              imageUrl: this.apiService.imagesDir+obj.info.image,
              imageWidth: 800,
              html: obj.info.description,
              allowOutsideClick: true,
  		          position : 'center'
             });
          }
        }else{

          this.localstorageService.set("warning_", [[obj.info.idwebsitewarning.toString(), datenow]]);

          Swal.fire({
            title: obj.info.name,
            imageUrl: this.apiService.imagesDir+obj.info.image,
            imageWidth: 800,
            html: obj.info.description,
            allowOutsideClick: true,
		          position : 'center'
           });


        }
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  addFilterColor(idcolor){ this.stopScroll = false;
    if(idcolor!=null && this.filter.color==null){
      this.filter.color = ""+idcolor;
    }else{
      idcolor = idcolor.toString();
      let colr = this.filter.color.toString().split(",");
      if(colr.indexOf(idcolor) != -1)
      {
         colr.splice(colr.indexOf(idcolor), 1);
      }else{
        colr.push(idcolor);
      }
      this.filter.color = colr.join(",");
      if(this.filter.color==""){
        this.filter.color = null;
      }
    }
  }

  addFilterSize(idsize){ this.stopScroll = false;
    if(idsize!=null && this.filter.size==null){
      this.filter.size = ""+idsize;
    }else{
      idsize = idsize.toString();
      let colr = this.filter.size.toString().split(",");
      if(colr.indexOf(idsize) != -1)
      {
         colr.splice(colr.indexOf(idsize), 1);
      }else{
        colr.push(idsize);
      }
      this.filter.size = colr.join(",");
      if(this.filter.size==""){
        this.filter.size = null;
      }
    }
  }


  addFilterMulti(id, op){ this.stopScroll = false;
    if(id!=null && this.filter[op]==null){
      this.filter[op] = ""+id;
    }else{
      id = id.toString();
      let colr = this.filter[op].toString().split(",");
      if(colr.indexOf(id) != -1)
      {
         colr.splice(colr.indexOf(id), 1);
      }else{
        colr.push(id);
      }
      this.filter[op] = colr.join(",");
      if(this.filter[op]==""){
        this.filter[op] = null;
      }
    }
  }


  stringtoarray(string){
    return string.split(",");
  }

  getname(id, list){
    if(list.length==0){
      return "";
    }
    let obj = list.find(o => o.id == id);
    if(!obj){
        return "";
    }

    if(typeof obj.langs!="undefined" && obj.langs[this.translate.currentLang] && obj.langs[this.translate.currentLang].description){
      return obj.langs[this.translate.currentLang].description;
    }
    if(typeof obj.langs!="undefined" && obj.langs[this.translate.currentLang]){
      return obj.langs[this.translate.currentLang];
    }
    return obj.description;
  }

  checkcolorSel(idcolor){
    if(this.filter.color==null){
      return false;
    }
    let colr = this.filter.color.toString().split(",");

    if(colr.indexOf(idcolor) != -1)
    {
       return true;
    }else{
      return false;
    }
  }


  checkFilterSelMult(id, op){
    if(this.filter[op]==null){
      return false;
    }
    let colr = this.filter[op].toString().split(",");

    if(colr.indexOf(id) != -1)
    {
       return true;
    }else{
      return false;
    }
  }


  trackByFn(index, item){
    return "item_"+item.iditem;
  }


  extractData(result){
    this.itemsList = result;
   if(this.itemsList==null){
     this.localstorageService.remove('detailBack');
     window.scrollTo(0, 0);
     this.getItems(0);
   }

   if(this.entryNgInit==true){
     this.extractDataEvent();
   }else{
     this.setIntervalClear = setInterval(() => {this.extractDataEvent(); }, 100);
   }
  // this.entryNgInit=true;
 }

  extractDataEvent(){

   if(!this.loadingShow && this.entryNgInit==true && this.localstorageService.get('show_position')<=document.body.scrollHeight){
     clearInterval(this.setIntervalClear);

     if(this.localstorageService.get('show_position')<500){
       window.scrollTo(0, this.localstorageService.get('show_position'));
     }else{
       window.scrollTo(0, this.localstorageService.get('show_position')+100);
     }
     this.localstorageService.remove('detailBack');
   }else{
     clearInterval(this.setIntervalClear);
     this.setIntervalClear = setInterval(() => {this.extractDataEvent(); }, 100);
   }
 }

  createBreadcrumbs(){
    this.breadcrumbs = [];


      if(this.filterInitial.search){
        this.breadcrumbs = [];



        let f = this.createBreadcrumbsP ;
        if(f.length){
          this.apiService.getbreadcrumbs(f, 'show').subscribe((obj: any) => {
            if(obj.code==200 && obj.info){
              this.breadcrumbs = [];
                this.createBreadcrumbsAll = obj.info;
                for (let index = 0; index < obj.info.length; index++) {

                  this.breadcrumbs.push({ "url": (typeof obj.info[index]["url"][this.translate.currentLang]!="undefined" ? obj.info[index]["url"][this.translate.currentLang] : obj.info[index]["url"][0] ), "description":(typeof obj.info[index]["description"][this.translate.currentLang]!="undefined" ? obj.info[index]["description"][this.translate.currentLang] : obj.info[index]["description"][0] ) });
                }

                this.savebreadcrumbs(this.breadcrumbs);
            }
          });

        }
      }else{

        this.apiService.getbreadcrumbs(this.createBreadcrumbsP, 'show').subscribe((obj: any) => {
          if(obj.code==200 && obj.info){
            this.breadcrumbs = [];
              this.createBreadcrumbsAll = obj.info;
              for (let index = 0; index < obj.info.length; index++) {

                this.breadcrumbs.push({ "url": (typeof obj.info[index]["url"][this.translate.currentLang]!="undefined" ? obj.info[index]["url"][this.translate.currentLang] : obj.info[index]["url"][0] ), "description":(typeof obj.info[index]["description"][this.translate.currentLang]!="undefined" ? obj.info[index]["description"][this.translate.currentLang] : obj.info[index]["description"][0] ) });
              }

              this.savebreadcrumbs(this.breadcrumbs);
          }
        });


      }

  }


/*  createBreadcrumbsRecursive(menu:any, obj:any , params:any, index:any){
    menu.forEach((menucicle: any, key: any) => {
        if(menucicle.id==this.filterInitial[menucicle.attr]){
          if(menucicle.descriptiontranslate){
            this.translate.get( menucicle.descriptiontranslate ).subscribe(translations => {

              let objSend:any = {};
              obj.push(this.lowercase.transform(this.urlpipePipe.transform(translations)));
              objSend.url = JSON.parse(JSON.stringify(obj));
              objSend.description=translations;



              if(this.route.snapshot.url[index].path!=this.lowercase.transform(this.urlpipePipe.transform(translations)) ){
                this.router.navigate( [this.localize.translateRoute('/404')]  , {skipLocationChange: true});return;
              }



              this.breadcrumbs.push(objSend);
            });
          }else{
            let objSend:any = {};
            obj.push(this.lowercase.transform(this.urlpipePipe.transform(menucicle.description))+"-"+menucicle.attr1+menucicle.id);
            objSend.url = JSON.parse(JSON.stringify(obj));
            objSend.description=menucicle.description;

            this.breadcrumbs.push(objSend);


            if(this.route.snapshot.url[index].path!=this.lowercase.transform(this.urlpipePipe.transform(menucicle.description))+"-"+menucicle.attr1+menucicle.id){
              this.router.navigate( [this.localize.translateRoute('/404')]  , {skipLocationChange: true});return;
            }
          }
          if(menucicle.level_info && menucicle.level_info.length>0){
            index++;
            this.createBreadcrumbsRecursive(menucicle.level_info, obj ,params, index);
          }
          return false;
        }
    });
  }*/

    replaceurl(menu:any,  op:any){
      menu.forEach((menucicle: any, key: any) => {
          if(menucicle.id==this.filterInitial[menucicle.attr]){
            if(menucicle.descriptiontranslate){
              this.translate.get( menucicle.descriptiontranslate ).subscribe(translations => {
                this.route.snapshot.url[op].path = this.lowercase.transform(this.urlpipePipe.transform(translations))  ;
              });
            }else{
              this.route.snapshot.url[op].path = this.lowercase.transform(this.urlpipePipe.transform(menucicle.description))+"-"+menucicle.attr1+menucicle.id ;
            }
            op = op+1;
            if(menucicle.level_info && menucicle.level_info.length>0 &&  this.route.snapshot.paramMap.get('desc'+op)){
              op = this.replaceurl(menucicle.level_info, op);
            }
            return false;
          }
      });
      return op;
    }


  savebreadcrumbs(breadscrumbs){

    this.localstorageService.set('createBreadcrumbsP', this.createBreadcrumbsP);
    this.localstorageService.set('createBreadcrumbsAll', this.createBreadcrumbsAll);
    this.localstorageService.set('show_breadscrumbs', breadscrumbs);
  }


  public setTitle( newTitle: string, extra: string = "") {
    let e = document.createElement('div');
    e.innerHTML = newTitle;
    let r = e.childNodes[0].nodeValue;
	 this.titleService.setTitle( r + " " +  extra);this.titlechangeService.send();
  }

  metatags(lang){
	  if(this.filter.search) {
		  this.translate.get( 'searchresult' ).subscribe(translations => {  this.titleService.setTitle( translations + " " + this.filter.search);this.titlechangeService.send();    });
      this.metaService.addTags([{name: 'robots', content: 'NOINDEX,NOFOLLOW'}]);
      return;
	  }

	let metaType = 9;
	let metaId = 1;
	let extraString = "";

	if(this.filter.menu) {
		metaId = this.filter.menu;
		metaType = 2;
		extraString += "m"+this.filter.menu;
	}
	if(this.filter.genre) {
		metaId = this.filter.genre;
		metaType = 8;
		extraString += "g"+this.filter.genre;
	}
	if(this.filter.cat) {
		metaId = this.filter.cat;
		metaType = 4;
		extraString += "c"+this.filter.cat;
	}
	if(this.filter.subcat) {
		metaId = this.filter.subcat;
		metaType = 5;
		extraString += "s"+this.filter.subcat;
	}
	if(this.filter.sub2cat) {
		metaId = this.filter.sub2cat;
		metaType = 6;
		extraString += "s2"+this.filter.sub2cat;
	}
	if(this.filter.brand) {
		metaId = this.filter.brand;
		metaType = 7;
		extraString += "b"+this.filter.brand;
	}

  if(!lang){
    return;
  }
  if(this.filter.menu=="-1"){
    metaId = 13;
		metaType = 9;
  }
    this.apiService.getMetatags(metaId,metaType,lang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.title && obj.title.content){
          this.setTitle( obj.title.content, extraString);
        }
        this.seoService.updateCanonicalUrl( location.protocol + '//' + location.host + location.pathname );
        if(obj.info.length==0){
          return;
        }


			Object.keys(obj.info).forEach(key => {

			  if(obj.info[key].name=="description") {
				obj.info[key].content =  obj.info[key].content + " " + extraString + " " ;
			  }

			});
			this.metaService.addTags(obj.info);



      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  checkoutGo(){
    this.modalService.close(this.modalDetailRef);
  }

  hideMenu(){
  /*  $(".div_select_order").hide();
    $(".div_select_brands").hide();
    $(".div_select_subcategories").hide();
    $(".div_select_sub2categories").hide();
    $(".div_select_colors").hide();
    $(".div_select_genres").hide();
		$(".chosen-brand b").addClass("closeSelect");
		$(".chosen-brand b").removeClass("openSelect");*/
  }




 @HostListener('window:scroll', ['$event'])
  onScrollw(event) {

    if((this.scrollPosition<window.scrollY-100 || this.scrollPosition>window.scrollY+100) && !this.localstorageService.get('detailBack')){
      this.scrollPosition = window.scrollY;
      this.localstorageService.set('show_position', this.scrollPosition);
    }
  }

  onScroll(event) {
    if(this.stopScroll==false){
      this.page++;
      this.localstorageService.set('show_page', this.page);
      this.getItems(1);
    }
  }


  urlmount(product:any, desc:any, iditem:any, idcolor?:any, idsize?:any, url?:any){


    let cat:any = "";
    let brand:any = "";
    let color:any = "";
    let pval:any = "";

    if(this.translate.currentLang && product.catlangs && product.catlangs[this.translate.currentLang] && product.catlangs[this.translate.currentLang].description) {
      cat = product.catlangs[this.translate.currentLang].description;
    }
    brand = (product.brandName) ? product.brandName : '';

    if(product.itemwithattr==1){
      let id_color = idcolor;
      if(!idcolor){
        id_color = this.color(product);
      }
      let id_size = idsize;
      if(!idsize){
        id_size = this.size(product, id_color);
      }
      color = product.colors[id_color][id_size].name;
      if(product.colors[id_color][id_size].priceFinal) { pval = product.colors[id_color][id_size].priceFinal; }
    }else{
      pval = product.priceFinal;
    }

    this.angulartics2GAEcommerce.ecAddProduct({
          'id': product.iditem,
          'name': desc,
          'category': cat,
          'brand': brand,
          'variant': color,
          'position': 0
        });
        this.angulartics2GAEcommerce.ecSetAction("click", {});



  this.angulartics2.eventTrack.next({
    action: 'productClick',
    properties: {
      label: 'productClick',
      currency: "EUR",
      content_type: 'product_group',
      "event":"productClick",
      gtmCustom:{
        'ecommerce': {
          'remove': undefined,
          'add': undefined,
          'purchase': undefined,
          'checkout': undefined,
          'detail': undefined,
          'impressions': undefined,
          'click': {
            'actionField': {'list': 'List Items Click'},    // 'detail'
            'products': [{
              'id': product.iditem,
              'name': desc,
              'price': pval,
              'brand': brand,
              'category': cat,
              'variant': color
             }]
           },
           'view_item': {
            'currency': 'EUR', 'value': pval,    // 'detail'
            'items': [{
              'item_id': product.iditem,
              'item_name': desc,
              'price': pval,
              'item_brand': brand,
              'item_category': cat,
              'item_variant': color
             }]
           }
         }
      }
    }
  });

  // setTimeout(()=>{
  //     if(idsize){
  //       this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor, idsize]);return;
  //     }else
  //     if(idcolor){
  //       this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem), idcolor]);return;
  //     }else{
  //       this.router.navigate( [this.localize.translateRoute("/product"),this.lowercase.transform(this.urlpipePipe.transform(desc)+"-"+iditem)]);return;
  //     }
  //   }, 100);
  }




  geturlalternate(){
    this.seoService.clearAlternateUrl();
    let desc1;
    let desc2;
    let desc3;
    let desc4;
    let desc5;
    if(this.route.snapshot.paramMap.get('desc1')){
      if(this.route.snapshot.paramMap.get('desc1')=="specials" || this.route.snapshot.paramMap.get('desc1')=="promotions" || this.route.snapshot.paramMap.get('desc1')=="promocoes"   ){
        desc1 = "m-1";
      }else{
        desc1 = this.geturlvalue(this.route.snapshot.paramMap.get('desc1'));
        if(desc1=="e"){
          desc1 = this.route.snapshot.paramMap.get('desc1');
        }
      }

    }
    if(this.route.snapshot.paramMap.get('desc2')){
       desc2 = this.geturlvalue(this.route.snapshot.paramMap.get('desc2'));
    }
    if(this.route.snapshot.paramMap.get('desc3')){
       desc3 = this.geturlvalue(this.route.snapshot.paramMap.get('desc3'));
    }
    if(this.route.snapshot.paramMap.get('desc4')){
       desc4 = this.geturlvalue(this.route.snapshot.paramMap.get('desc4'));
    }
    if(this.route.snapshot.paramMap.get('desc5')){
       desc5 = this.geturlvalue(this.route.snapshot.paramMap.get('desc5'));
    }
    this.apiService.getUrlAlternate(desc1, desc2, desc3, desc4, desc5).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){

        this.urlsAlternate = obj.info;

        for (var [key, value] of Object.entries(obj.info)) {
          this.seoService.updateAlternateUrl(value.toString(), key);
        }

        this.seoService.updateAlternateUrl(obj.info['fr'], 'x-default');


      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  stopScrollF(v){
    this.stopScroll=v;
    this.localstorageService.set('show_stopScroll', this.stopScroll);
  }

  ngOnInit() {


      this.relateditemarr = [];


    this.seoService.updateCanonicalUrl( location.protocol + '//' + location.host + location.pathname );
    this.createBreadcrumbs();
    this.apiService.GET_TAGS_META.forEach(key => {
      this.metaService.removeTag('name="' + key + '"');
    });
    if(!(this.localstorageService.get('detailBack') && this.localstorageService.get('detailBack')==1)){
      this.scrollPosition = 0;

      this.localstorageService.set('show_position', 0);
    }else{
      if(this.localstorageService.get('show_position')>100){
        window.scrollTo(0, 100);
      }else{
        window.scrollTo(0, 0);
      }
    }
    this.geturlalternate();
    this.metatags(this.translate.currentLang);
    if(!this.localstorageService.get('detailBack')){

      this.page=1;
      this.localstorageService.set('show_page', this.page);
      this.stopScroll=false;
      this.localstorageService.set('show_stopScroll', this.stopScroll);
      this.getBrandsFilter();
      this.getCountryOriginFilter();

      this.sub2cat=null;
      this.subcat=null;
      this.cat=null;

      this.localstorageService.set('show_subcat', null);
      this.localstorageService.set('show_cat', null);
      this.localstorageService.set('show_sub2cat', null);

      if(this.filter.cat!=null && this.filter.cat>0){
        if(this.filter.subcat!=null && this.filter.subcat>0){
          this.getSub2CatFilter();
        }else{
          this.getSubCatFilter();
        }
      }else{
        this.getCatFilter();
      }


      this.getColors();
      this.getGenres();
      this.getSizes();
      this.getItems(0);

    }else{

    }
    this.getDescText();
  }

  ngOnDestroy(): void {
    this.seoService.clearAlternateUrl();
    this.seoService.removeTag("rel=canonical");
    if(this.subscribe){
      this.subscribe.unsubscribe();
    }
    if(this.subscribeMenu){
      this.subscribeMenu.unsubscribe();
    }
    for (var i = 0; i < this._subscription.length; i++) {
       if(this._subscription[i]){
         this._subscription[i].unsubscribe();
       }
    }
  }

  viewInit(){
    this.entryNgInit=true;
  }
  ngAfterViewInit(){

  /*  if(this.localstorageService.get('detailBack')){
      this.localstorageService.remove('detailBack');

      let rt = document.getElementById("menu_header").offsetHeight;
console.log(rt);
      if(this.scrollPosition<rt){
        window.scrollTo(0, this.scrollPosition);
      }else{
        window.scrollTo(0, this.scrollPosition+rt);
      }
    }*/
    //this.entryNgInit=true;
  }


  getDescText(){
    this.descText=null;
    this.descTextMenu=null;
    if(this.filter.menu<0){
      return 0;
    }
    var type=0;
    var id=0;
    if(this.filter.genre){
      type=0;
      id=this.filter.genre;
    }
    if(this.filter.cat){
      type=1;
      id=this.filter.cat;
    }
    if(this.filter.subcat){
      type=2;
      id=this.filter.subcat;
    }
    if(this.filter.sub2cat){
      type=3;
      id=this.filter.sub2cat;
    }
    if(this.filter.brand){
      type=4;
      id=this.filter.brand;
    }

      this.apiService.getShowDescription(type, id, 5, this.filter.menu).subscribe((obj: any)=>{
        if(typeof obj!=="undefined" && obj.code==200){

          if( Number(this.filter.menu) == 4 && Number(id) != 0){
            this.descText = obj.info;
            this.descTextMenu = null;
          }
          else if(Number(this.filter.menu) == 4 && Number(id) == 0){
            this.descText = null;
            this.descTextMenu = obj.menu;
          }
          else{
            this.descText = obj.info;
            this.descTextMenu = obj.menu;
          }
          
        }else{
          this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
        }
      });
  }


cicle(color: any){
  let key = Object.keys(color)[0];
  return color[key].images;
}

showcolorInfo(color:any, op:any){
  let key = Object.keys(color.value)[0];
  if(op==1){
    return color.value[key].code;
  }else{
    if(!color.value[key].langs[this.translate.currentLang]){
      return color.value[key].name;
    }
    return color.value[key].langs[this.translate.currentLang].name;
  }
}

  loadImg(item: any){
    if(item.itemwithattr==1){
      if(item.colorpriority && item.colorpriority in item.colors){
        let key = Object.keys(item.colors[item.colorpriority])[0];
        if(this.browserwebp && item.colors[item.colorpriority][key].images[0].webp){
          return this.apiService.imagesDir+item.colors[item.colorpriority][key].images[0].webp;
        }
        return this.apiService.imagesDir+item.colors[item.colorpriority][key].images[0].photo;
      }else{
        let color = Object.keys(item.colors)[0];
        let key = Object.keys(item.colors[color])[0];
        if(this.browserwebp && item.colors[color][key].images[0].webp){
          return this.apiService.imagesDir+item.colors[color][key].images[0].webp;
        }
        return this.apiService.imagesDir+item.colors[color][key].images[0].photo;
      }
    }else{
        if(this.browserwebp && item.images[0].webp){
          return this.apiService.imagesDir+item.images[0].webp;
        }
        return this.apiService.imagesDir+item.images[0].photo;
    }
  }

  getColorSelect(item: any){
    if(item.itemwithattr==1){
      if(item.colorpriority && item.colorpriority in item.colors){
        return item.colorpriority;
      }else{
        let color = Object.keys(item.colors)[0];
        return color;
      }
    }
    return 0;
  }


  getBackgorundColor(color:any){
    let key = Object.keys(color)[0];
    return  this.apiService.imagesDir+color[key].images[0].photo ;
  }

  createImageAltAttr(color:any): string{
    let key = Object.keys(color)[0];
    if(color[key].langs && color[key].langs[this.translate.currentLang] && color[key].langs[this.translate.currentLang].name && color[key].langs[this.translate.currentLang].name != ''){
      return color[key].langs[this.translate.currentLang].name;
    }
    else{
      if(color[key].name && color[key].name != ''){
        return color[key].name
      }
      else{
        return '';
      }
    }
  }

  showPrice(item:any, op:Number){
    if(op==1){
      if(item.itemwithattr==1){
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = Object.keys(item.colors[item.colorpriority])[0];
          return item.colors[item.colorpriority][key].priceOriginalTxt;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceOriginalTxt;
        }
      }else{
        return item.priceOriginalTxt;
      }
    }else{
      if(item.itemwithattr==1){
        if(item.colorpriority && item.colorpriority in item.colors){
          let key = Object.keys(item.colors[item.colorpriority])[0];
          return item.colors[item.colorpriority][key].priceFinalTxt;
        }else{
          let color = Object.keys(item.colors)[0];
          let key = Object.keys(item.colors[color])[0];
          return item.colors[color][key].priceFinalTxt;
        }
      }else{
        return item.priceFinalTxt;
      }
    }

  }

  showDiscount(item:any){
    if(item.typepromotion==1){
      return Math.floor( parseFloat(item.promotionvalue) );
    }
    if(item.itemwithattr==1){
      if(item.colorpriority && item.colorpriority in item.colors){
        let key = Object.keys(item.colors[item.colorpriority])[0];
        return   Math.floor(Math.round((100-(100*item.colors[item.colorpriority][key].priceFinal/item.colors[item.colorpriority][key].priceOriginal))* 100) / 100);
      }else{
        let color = Object.keys(item.colors)[0];
        let key = Object.keys(item.colors[color])[0];
        return Math.floor(Math.round((100-(100*item.colors[color][key].priceFinal/item.colors[color][key].priceOriginal))* 100) / 100);
      }
    }else{
      return Math.floor(Math.round((100-(100*item.priceFinal/item.priceOriginal))* 100) / 100);
    }

  }


  getBrandsFilter(){
    this.apiService.getBrandsFilter(this.filter).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.brands = obj.info;
        this.localstorageService.set('show_brands', this.brands);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  getCountryOriginFilter(){
    this.apiService.getCountryOriginFilter(this.filter).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.countriesOrigin = obj.info;
        this.localstorageService.set('show_countriesorigin', this.countriesOrigin);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getSubCatFilter(){
    this.apiService.getSubCatFilter(this.filter).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.subcat = obj.info;
        this.subcatStock = obj.stockoff;
        this.localstorageService.set('show_subcat', this.subcat);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }



  getCatFilter(){
    if(this.filter.search && this.filter.search!=""){
      this.cat = null;
      this.localstorageService.set('show_cat', null);
      return 0;
    }
    this.apiService.getCatFilter(this.filter).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.cat = obj.info;
        this.catStock = obj.stockoff;
        this.localstorageService.set('show_cat', this.cat);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getSub2CatFilter(){
    this.apiService.getSub2CatFilter(this.filter).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.sub2cat = obj.info;
        this.sub2catStock = obj.stockoff;
        this.localstorageService.set('show_sub2cat', this.sub2cat);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  getColors(){
    this.apiService.getColorsFilter(this.filter).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.colors = obj.parent;
        this.localstorageService.set('show_colors', this.colors);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }

  getSizes(){
    this.apiService.getSizesFilter(this.filter).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.sizes = obj.info;
        this.localstorageService.set('show_sizes', this.sizes);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => { Swal.fire(translations); });
      }
    });
  }

  getGenres(){
    this.apiService.getGenresFilter(this.filter).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        this.genres = obj.info;
        this.localstorageService.set('show_genres', this.genres);
      }else{
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire(translations);    });
      }
    });
  }


  filterNewItem(){
    var element = <HTMLInputElement> document.getElementById("new_filter");
    var isChecked = element.checked;
    if(isChecked){
  		this.filter.novelty=1;
  	}else{
  		this.filter.novelty=0;
  	}
  }

  cleanFilters(){
    this.filter = Object.assign({}, this.filterInitial);
    this.filter.brand = null;
    this.orderItem=0;
    if(this.filter.novelty==1){
      $("#new_filter").prop("checked", true);
    }else{
      $("#new_filter").prop("checked", false);
    }
    this.localstorageService.set('show_filter', this.filter);
    this.stopScroll=false;
    this.localstorageService.set('show_stopScroll', this.stopScroll);
    this.getItems(0);
  }

  calcUrlPromotions(){

    let promotion = this.translate.instant('stockoff');
    let finalurl = [this.localize.translateRoute('/show') ];
    let desc1 = this.urlpipePipe.transform(promotion).toLocaleLowerCase();
    let desc2 = this.route.snapshot.paramMap.get('desc2');
    let desc3 = this.route.snapshot.paramMap.get('desc3');
    let desc4 = this.route.snapshot.paramMap.get('desc4');
    let desc5 = this.route.snapshot.paramMap.get('desc5');
    let desc6 = this.route.snapshot.paramMap.get('desc6');
    finalurl.push(desc1);
    if(desc2){finalurl.push(desc2);}
    if(desc3){finalurl.push(desc3);}
    if(desc4){finalurl.push(desc4);}
    if(desc5){finalurl.push(desc5);}
    if(desc6){finalurl.push(desc6);}
    this.router.navigate(finalurl);
  }

  calcnewurl(desc, abbr, id){

    if(desc!=""){
      desc = this.lowercase.transform(this.urlpipePipe.transform(desc));
      desc += "-"+abbr+id;
    }
    let finalurl = [this.localize.translateRoute('/show') ];
    let desc1 = this.route.snapshot.paramMap.get('desc1');
    let desc2 = this.route.snapshot.paramMap.get('desc2');
    let desc3 = this.route.snapshot.paramMap.get('desc3');
    let desc4 = this.route.snapshot.paramMap.get('desc4');
    let desc5 = this.route.snapshot.paramMap.get('desc5');
    if(!desc1){
      return "";
    }
    let entry = false;
    let objcal;let objcalletter;
    if(desc1=="novidades" || desc1=="news" || desc1=="nouveautes" || desc1=="novedades"  ){
      finalurl.push(desc1);
    }else if(desc1=="specials" || desc1=="promotions" || desc1=="promocoes"   ){
      finalurl.push(desc1);
    }else{
       objcal = this.geturlvalue(desc1);
       objcalletter = objcal.slice(0,1);
       if(objcalletter==abbr){
         if(desc!=""){
           finalurl.push(desc);
         }
         desc2=null;desc3=null;desc4=null;desc5=null;
         entry = true;
       }else{
         finalurl.push(desc1);
       }
    }

    if(desc2){
      objcal = this.geturlvalue(desc2);
      objcalletter = objcal.slice(0,1);
      if(objcalletter==abbr){
        if(desc!=""){
          finalurl.push(desc);
        }
         entry = true;
         desc3=null;desc4=null;desc5=null;
      }else{
        finalurl.push(desc2);
      }
    }else if(!entry){
      if(desc!=""){
        finalurl.push(desc);
      }
       entry = true;
       desc3=null;desc4=null;desc5=null;
    }


    if(desc3){
      objcal = this.geturlvalue(desc3);
      objcalletter = objcal.slice(0,1);
      if(objcalletter==abbr){
        if(desc!=""){
          finalurl.push(desc);
        }
         desc4=null;desc5=null;
         entry = true;
      }else{
        finalurl.push(desc3);
      }
    }else if(!entry){
      if(desc!=""){
        finalurl.push(desc);
      }
      entry = true;
       desc4=null;desc5=null;
    }


    if(desc4){
      objcal = this.geturlvalue(desc4);
      objcalletter = objcal.slice(0,1);

      if(objcalletter==abbr){
        if(desc!=""){
          finalurl.push(desc);
        }
        entry = true;
         desc5=null;
      }else{
        finalurl.push(desc4);
      }
    }else if(!entry){
      if(desc!=""){
        finalurl.push(desc);
      }
      entry = true;
       desc5=null;
    }

    if(desc5){
      if(desc!=""){
        finalurl.push(desc);
      }
      entry = true;
    }else if(!entry){
      entry = true;
      if(desc!=""){
        finalurl.push(desc);
      }
    }
    this.router.navigate( finalurl  );
  }

  closeModalFilters(){
    this.modalService.close(this.modalDetailRef2);
  }

  getItems(i:any){
    this.loadingShow=true;
    if(i==0){
      this.page = 1;
      this.itemsList = null;
      this.localstorageService.set('show_itemsList' , null, 1);
      this.localstorageService.set('show_page', this.page);
    }
    this.localstorageService.set('show_filter', this.filter);
    this.localstorageService.set('show_orderItem', this.orderItem);

    let filters = Object.assign({}, this.filter);
    if(this.filter.color!=null && this.filter.color!="null"){
      let allcolors = [];
      let colr1 = this.filter.color.toString().split(",");
      for (let index = 0; index < colr1.length; index++) {
        let colr = this.colors.find(i => i.id === colr1[index]);
        if(colr){
          allcolors.push(colr.child);
        }
      }
      filters.color = allcolors.join(",");
    }


      this.relateditemarr = [];
    this.apiService.getItemsShow(filters, this.orderItem, this.page, this.translate.currentLang).subscribe((obj: any)=>{
      if(typeof obj!=="undefined" && obj.code==200){
        if(obj.info.length==0){
          if(this.page==1){
            this.emptyItem=1;
            this.localstorageService.set('show_emptyItem', this.emptyItem);
            if(typeof obj.related!="undefined") {
              this.relateditemarr = obj.related;
            }
            // this.router.navigate( [this.localize.translateRoute('/404')]  );return;
          }

          this.stopScroll = true;
          this.localstorageService.set('show_stopScroll', this.stopScroll);

          if(i==0){
            this.itemsList = obj.info;
            this.localstorageService.set('show_itemsList' , obj.info, 1);
            //this.keeplistservice.send(obj.info,this.page);
          }
        }else{
          this.emptyItem=0;
          this.localstorageService.set('show_emptyItem', this.emptyItem);



          if(i==0 || !this.itemsList){
            this.itemsList = obj.info;
          }else{
            let iList = this.itemsList.concat(obj.info);
            this.itemsList = iList;
          }
          this.localstorageService.set('show_itemsList', this.itemsList, 1);



          let desc:any= "";
  			let cat:any = "";
        let brand:any = "";
        let color:any = "";
        let pval:any = "";
        let idcolor:any;
        let idsize:any;
        let galayer:any = [];
        let galayerga4:any = [];
        for (let index = 0; index < obj.info.length; index++) {
          if(this.translate.currentLang && obj.info[index].langs[this.translate.currentLang]){
    			  desc = obj.info[index].langs[this.translate.currentLang].description;
    			}else{
    			  desc = obj.info[index].description;
    			}
          if(this.translate.currentLang && obj.info[index].catlangs && obj.info[index].catlangs[this.translate.currentLang] && obj.info[index].catlangs[this.translate.currentLang].description) {
    				cat = obj.info[index].catlangs[this.translate.currentLang].description;
    			}
          brand = (obj.info[index].brandName) ? obj.info[index].brandName : '';
          if(obj.info[index].itemwithattr==1){
            idcolor = Object.keys(obj.info[index].colors)[0];
            idsize = Object.keys(obj.info[index].colors[idcolor])[0];
            color = obj.info[index].colors[idcolor][idsize].name;
            if(obj.info[index].colors[idcolor][idsize].priceFinal) { pval = obj.info[index].colors[idcolor][idsize].priceFinal; }
          }else{
            pval = obj.info[index].priceFinal;
          }

          this.angulartics2GAEcommerce.ecAddImpression({
                'id': obj.info[index].iditem,
                'name': desc,
                'category': cat,
                'brand': brand,
                'variant': color,
                'position': index
              });

              galayer.push({
                'id': obj.info[index].iditem,
                'name': desc,
                'price': pval,
                'brand': brand,
                'category': cat,
                'variant': color
              });

              galayerga4.push({
                'item_id': obj.info[index].iditem,
                'item_name': desc,
                'price': pval,
                'item_brand': brand,
                'item_category': cat,
                'item_variant': color
              });
        }

        this.angulartics2.eventTrack.next({
  				action: 'impressions',
  				properties: {
            label: 'impressions',
  					currency: "EUR",
            "event":"Impressions",
            gtmCustom:{
              'ecommerce': {
                'remove': undefined,
                'add': undefined,
                'purchase': undefined,
                'checkout': undefined,
                'detail': undefined,
                'click': undefined,
                'impressions': {
                  'actionField': {'list': 'List Results'},    // 'detail'
                  'products': galayer
                 },
                 'view_item_list': {
                  'item_list_id': 'List_Results',    // 'detail'
                  'items': galayerga4
                 }
               }
            }
  				}
  			});
        }
        this.loadingShow=false;
      }else{
        this.loadingShow=false;
        this.translate.get( 'ERROR_CONNECT_API' ).subscribe(translations => {  Swal.fire("ERROR_CONNECT_API");    });
      }
    });
  }


  changeColor(color, size?:any){
    this.selectItem.colorActive = color;
    if(size){
      this.selectItem.sizeActive = size;
    }else{
      this.selectItem.sizeActive = Object.keys(this.selectItem.colors[this.selectItem.colorActive])[0];
    }

  }

  changeSize(size){
    this.selectItem.sizeActive =size;
  }

loadPriceFastBuy(op:Number){
  if(!this.selectItem){
    return 0;
  }
  if(op==1){
    if(this.selectItem.itemwithattr==1){
      return this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].priceOriginalTxt;
    }else{
      return this.selectItem.priceOriginalTxt;
    }
  }else{
    if(this.selectItem.itemwithattr==1){
      return this.selectItem.colors[this.selectItem.colorActive][this.selectItem.sizeActive].priceFinalTxt;
    }else{
      return this.selectItem.priceFinalTxt;
    }
  }
}

  color(i:any){
    if(i.itemwithattr==1){
      if(i.colorpriority && i.colors[i.colorpriority] ){
        return i.colorpriority;
      }else{
        return Object.keys(i.colors)[0];
      }
    }else{
      return "";
    }
  }

  size(i:any, op:any){
    if(i.itemwithattr==1){
      if(op>0){
        return Object.keys(i.colors[op])[0];
      }
      if(i.colorpriority && i.colors[i.colorpriority] ){
        return Object.keys(i.colors[i.colorpriority])[0];
      }else{
        let col = Object.keys(i.colors)[0];
        return Object.keys(i.colors[col])[0];
      }
    }else{
      return "";
    }
  }

  openModal(item, index){
    this.modalDetailRef = this.modalService.open(this.ModalDetail, {
        size: 'md',
        windowClass: 'modal-md'  ,
        centered: true,
        animation: true,
        hideCloseButton: true,
        keyboard: false,
        closeOnOutsideClick: false,
  backdropClass: 'modal-backdrop'
});
this.modalDetailRef.onOpen.subscribe(() => {
        $("modal .fade.show").addClass("in");
    });
    this.selectItem = item;
    this.selectItem.indexitemlist = index;
    if(item.itemwithattr==1){
      if(item.colorpriority && item.colors[item.colorpriority] ){
        this.selectItem.colorActive = item.colorpriority;
      }else{
        this.selectItem.colorActive = Object.keys(item.colors)[0];
      }
      this.selectItem.sizeActive = Object.keys(item.colors[this.selectItem.colorActive])[0];
    }


    }

    closeModal(){
        this.modalService.close(this.modalDetailRef);
    }


    incProd(quantity:any, item:any, color?:any, size?:any){
      let quantityinput ;
      if(!color){
        quantityinput = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+item)).value);
      }else{
        quantityinput = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+item+"_"+color+"_"+size)).value);
      }
      if(isNaN(quantityinput)){
        quantityinput = 0;
      }
      quantityinput++;
      if(quantityinput>parseInt(quantity)){
        quantityinput = parseInt(quantity);
      }
      if(!color){
        (<HTMLInputElement>document.getElementById("qtd_stock_"+item)).value = quantityinput;
      }else{
        (<HTMLInputElement>document.getElementById("qtd_stock_"+item+"_"+color+"_"+size)).value = quantityinput;
      }
    }

    descProd(quantity:any, item:any, color?:any, size?:any){
      let quantityinput ;
      if(!color){
        quantityinput = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+item)).value);
      }else{
        quantityinput = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+item+"_"+color+"_"+size)).value);
      }
      if(isNaN(quantityinput)){
        quantityinput = 0;
      }
      quantityinput--;
      if(quantityinput<0){
        quantityinput = 0;
      }
      if(!color){
        (<HTMLInputElement>document.getElementById("qtd_stock_"+item)).value = quantityinput;
      }else{
        (<HTMLInputElement>document.getElementById("qtd_stock_"+item+"_"+color+"_"+size)).value = quantityinput;
      }
    }


    stockajust(quantity:any, item:any, color?:any, size?:any){
      let quantityinput ;
      if(!color){
        quantityinput = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+item)).value);
      }else{
        quantityinput = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+item+"_"+color+"_"+size)).value);
      }
      if(isNaN(quantityinput)){
        quantityinput = 0;
      }
      if(quantityinput<0){
        quantityinput = 0;
      }
      if(quantityinput>parseInt(quantity)){
        quantityinput = parseInt(quantity);
      }
      if(!color){
        (<HTMLInputElement>document.getElementById("qtd_stock_"+item)).value = quantityinput;
      }else{
        (<HTMLInputElement>document.getElementById("qtd_stock_"+item+"_"+color+"_"+size)).value = quantityinput;
      }
    }


    buyProduct(){
      let arr:any=[];
      let obj: any = {};

      let desc:any= "";
    let cat:any = "";
    let brand:any = "";
    let color:any = "";
    let pval:any = "";
    let arrsendlitics = [];
    let arrsendliticsga4 = [];

    let aux_q=0;

    let valueInput = false;
    if(this.translate.currentLang && this.selectItem.langs[this.translate.currentLang]){
      desc = this.selectItem.langs[this.translate.currentLang].description;
    }else{
      desc = this.selectItem.description;
    }

    if(this.translate.currentLang && this.selectItem.catlangs && this.selectItem.catlangs[this.translate.currentLang] && this.selectItem.catlangs[this.translate.currentLang].description) {
      cat = this.selectItem.catlangs[this.translate.currentLang].description;
    }
    brand = (this.selectItem.brandName) ? this.selectItem.brandName : '';
    obj.iditem = this.selectItem.iditem;
    obj.idmanager_company = this.selectItem.idmanager_company;
    obj.iditem_integration = this.selectItem.iditem_integration;


      if(this.selectItem && this.selectItem.itemwithattr==1){
        let copy ;
        for (var [key_1, value_1] of Object.entries(this.selectItem.colors)) {
          for (var [key_2, value_2] of Object.entries(this.selectItem.colors[key_1])) {

              copy = Object.assign({}, obj);

              if((<HTMLInputElement>document.getElementById("qtd_stock_"+copy.iditem+"_"+key_1+"_"+key_2)).value==""){
                copy.quantitycomplete = 0;
              }else{
                  valueInput = true;
                copy.quantitycomplete = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+copy.iditem+"_"+key_1+"_"+key_2)).value);

                if(this.selectItem.colors[key_1][key_2].priceFinal) { pval = this.selectItem.colors[key_1][key_2].priceFinal; }
                color = this.selectItem.colors[key_1][key_2].name;
                arrsendlitics.push({
                    'id': this.selectItem.iditem,
                    'name': desc,
                    'price': pval,
                    'brand': brand,
                    'category': cat,
                    'variant': color,
                    'quantity': copy.quantitycomplete
                   });

                   arrsendliticsga4.push({
                    'item_id': this.selectItem.iditem,
                    'item_name': desc,
                    'price': pval,
                    'item_brand': brand,
                    'item_category': cat,
                    'item_variant': color,
                    'quantity': copy.quantitycomplete
                   });

              }
              copy.quantity = copy.quantitycomplete;
              copy.idcolor = key_1;
              copy.idsize = key_2;
              copy.fullcode = this.selectItem.code+"-"+key_1+"-"+key_2;
              copy.idcolor_integration = this.selectItem.colors[key_1][key_2].idcolor_integration ;
              copy.idsize_integration = this.selectItem.colors[key_1][key_2].idsize_integration ;
              arr.push(copy);

          }
        }




      }else{
        pval = this.selectItem.priceFinal;

        if((<HTMLInputElement>document.getElementById("qtd_stock_"+obj.iditem)).value==""){
          aux_q=0;
        }else{
          aux_q = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+obj.iditem)).value);
          valueInput = true;
        }

        arrsendlitics.push({
        'id': this.selectItem.iditem,
        'name': desc,
        'price': pval,
        'brand': brand,
        'category': cat,
        'variant': color,
        'quantity': aux_q
       });
       arrsendliticsga4.push({
        'item_id': this.selectItem.iditem,
        'item_name	': desc,
        'price': pval,
        'item_brand': brand,
        'item_category': cat,
        'item_variant': color,
        'quantity': aux_q
       });
        obj.quantitycomplete = aux_q;
        obj.quantity = obj.quantitycomplete;
        obj.fullcode = this.selectItem.code;
        arr.push(obj);
      }

      if(!valueInput){
        Swal.fire( this.translate.instant('INSERT_QTD_REQUIRED') );
        return 0;
      }

      this.apiService.addCart(arr).subscribe((obj: any)=>{

        if(typeof obj!=="undefined" && obj.code==200){


          this.sharedService.sendClickEvent(this.translate.currentLang);
          this.translate.get( obj.msg ).subscribe(translations => {
            Swal.fire({
              position: 'center',
              customClass: {
                container: 'swal-index',
                confirmButton: 'modalRemoveBackground modalHoverColor',
                cancelButton: 'modalRemoveBackground modalHoverColor'
              },
              icon: 'success',
              width:'35em',
              text: this.translate.instant('keepshopping'),
              title: translations,
              showConfirmButton: true,
              showCancelButton: true,
              reverseButtons:true,
              confirmButtonText: this.translate.instant('checkout'),
              cancelButtonText: this.translate.instant('keepbuying')
            }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                    this.modalService.close(this.modalDetailRef);
                     this.router.navigate( [this.localize.translateRoute('/checkout')]  );
                  }
                });
          });



          if(this.selectItem && this.selectItem.itemwithattr==1){
            let q_t_d_1= 0;
            for (var [key_1, value_1] of Object.entries(this.selectItem.colors)) {
              for (var [key_2, value_2] of Object.entries(this.selectItem.colors[key_1])) {
                  if((<HTMLInputElement>document.getElementById("qtd_stock_"+this.selectItem.iditem+"_"+key_1+"_"+key_2)).value==""){
                    q_t_d_1 = 0;
                  }else{
                    q_t_d_1 = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+this.selectItem.iditem+"_"+key_1+"_"+key_2)).value);
                  }
                  this.itemsList[this.selectItem.indexitemlist].colors[key_1][key_2].qtdCart = q_t_d_1;
              }
            }

          }else{
            this.itemsList[this.selectItem.indexitemlist].qtdCart = parseInt((<HTMLInputElement>document.getElementById("qtd_stock_"+this.selectItem.iditem)).value);
          }



          arrsendlitics.forEach((val) => {

          this.angulartics2GAEcommerce.ecAddProduct({
                'id': val.id,
                'name': val.name,
                'category': val.category,
                'brand': val.brand,
                'variant': val.variant,
                'price': val.price,
                'quantity': val.quantity,
                'position': 0
              });
              this.angulartics2GAEcommerce.ecSetAction("add", {});

				});


        this.angulartics2.eventTrack.next({
  				action: 'addToCart',
  				properties: {
            label: 'addToCart',
  					currency: "EUR",
  					content_type: 'product_group',
            "event":"addToCart",
            gtmCustom:{
              'ecommerce': {
                'remove': undefined,
                'click': undefined,
                'purchase': undefined,
                'checkout': undefined,
                'detail': undefined,
                'impressions': undefined,
                'add': {
                  'actionField': {'list': 'addToCart'},    // 'detail'
                  'products': arrsendlitics
                 },
                 'add_to_cart': {
                  'currency': 'EUR', 'value':pval,    // 'detail'
                  'items': arrsendliticsga4
                 }
               }
            }
  				}
  			});
          this.modalService.close(this.modalDetailRef);
        }else{
          if(obj.code==302){
            this.modalService.close(this.modalDetailRef);
             this.router.navigate( [this.localize.translateRoute('/signin')]  );
          }
          this.translate.get( obj.msg ).subscribe(translations => {  Swal.fire({title:translations, customClass: { container: 'swal-index' } });    });
        }
      });
    }


    parseIntValue(val){
      return parseInt(val);
    }

    changeTextMobile(arr, pos){
      let item1 = arr.find(i => i.id === pos);
      if(item1 && item1.langs && item1.langs[this.translate.currentLang]){
        return item1.langs[this.translate.currentLang].description;
      }else if(item1){
        return item1.description;
      }
    }

    changeTextMobile2(arr, pos){
      let item1 = arr.find(i => i.id === pos);
      if(item1){
        return item1.code;
      }
    }


    geturlvalue(s){
      let t = [];
      let num ;
      for (let _i = s.length-1; _i >= 0; _i--) {
         num = s[_i];
      	t.push(num);
        if((/[a-zA-Z]/).test(num)){
      	  break;
        }
      }
      let t1=t.reverse().join("").toString();
      return t1;
    }

   getdescriptionurl(desc){
     switch (desc) {
        case 'm':
          return "menu";
          break;
        case 'g':
          return "genre";
          break;
        case 'c':
          return "cat";
          break;
        case 's':
          return "subcat";
          break;
        case 'b':
          return "brand";
          break;
        case 'o':
          return "collection";
          break;
        case 'u':
          return "sub2cat";
          break;
        case 'e':
          return "search";
          break;
        default:
          return "0";
      }
   }

   returnZero() {
    return 0
  }
}
